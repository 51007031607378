import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  primaryButton: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: '500',
    backgroundColor: '#2DAA67',
    borderRadius: 25,
    zIndex: 1,

    height: props => props.height,
    padding: '5px 10px',

    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '14px'
    },
    fontSize: '10px',

    '&:hover': {
      backgroundColor: '#2DAA67'
    }
  }, 
  contactButton: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: '500',
    backgroundColor: '#2DAA67',
    borderRadius: 25,
    zIndex: 1,
    textWrap: 'nowrap',

    height: props => props.height,
    padding: '5px 10px',

    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '14px'
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px'
    },
    fontSize: '10px',

    '&:hover': {
      backgroundColor: '#2DAA67'
    }
  }
}))

// eslint-disable-next-line react/prop-types
export default function PrimaryButton({ text, onClick, isContactButton}) {
  const classes = useStyles()

  return (
    <Button onClick={onClick} className={!isContactButton ? classes.primaryButton : classes.contactButton}>
      {text.toString().toUpperCase()}
    </Button>
  )
}

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func
}
