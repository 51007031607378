import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import { Box, Button, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  submitButton: {
    border: 'none',
    textAlign: 'center',
    height: theme.spacing(6),
    minWidth: '200px',
    width: '10%',
    background: '#2DAA67',
    borderRadius: theme.spacing(3),
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#269158'
      // backgroundColor: '#F50057'
    }
  }
}))

export function ProjectGrid({ projects }) {
  const classes = useStyles()
  const isSmallScreen = useMediaQuery('(max-width:576px)')

  return (
    <Box>
      <motion.div
        layout
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        className="projects-grid"
      >
        {projects.map(project => (
          <ProjectCard key={`${project.slug}_${project.id}`} project={project} />
        ))}
      </motion.div>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Button
          size={isSmallScreen ? 'small' : 'medium'}
          className={classes.submitButton}
          onClick={() => window.open(`${process.env.PUBLIC_URL}/assets/files/TISOHA_PORTFOLIO.pdf`, '_blank', 'noopener noreferrer')}
        >
          <span style={{ color: 'white', textDecoration: 'none' }}>View More Portfolio</span>
        </Button>
      </Box>
    </Box>
  )
}

const ProjectCard = ({ project }) => {
  return (
    <motion.div layout className="project-card">
      <AnimatePresence >
        <img className="project-cover" alt={project.cover.alt} src={project.cover.relativeUrl} />
        <div className="project-card--overlay">
          <h2 className="project-card--title">
            <a href={'/portfolio/' + project.slug}>{project.title}</a>
          </h2>
          <p
            className="project-card--description"
            dangerouslySetInnerHTML={{ __html: project.shortDescription }}
          />
        </div>
      </AnimatePresence>
    </motion.div>
  )
}

ProjectGrid.propTypes = {
  projects: PropTypes.array.isRequired
}
ProjectCard.propTypes = {
  project: PropTypes.object.isRequired
}
