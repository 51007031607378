import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import Footer from '../../components/Footer/Footer'
import SectionContainer from '../../components/SectionContainer'
import TypoOutlined from '../../components/Typography/TypoOutlined'
import { ProjectFilter, ProjectGrid } from './components'

import '../../scss/styles.scss'
import projects from '../../data/projects'

const FILTERS = [
  {
    name: 'All',
    code: 'all'
  },
  {
    name: 'Mobile',
    code: 'mobile'
  },
  {
    name: 'Web',
    code: 'web'
  },
  {
    name: 'AI',
    code: 'ai'
  }
]

export default function PortfolioPage() {
  const [activeFilter, setActiveFilter] = useState(FILTERS[0].code)
  const [selectedProjects, setSelectedProjects] = useState(projects)
  const filters = FILTERS

  const handleFilterClick = filter => {
    setActiveFilter(filter.code)
  }

  useEffect(() => {
    let newProjects

    if (activeFilter === 'all') {
      newProjects = projects
    } else {
      newProjects = projects.filter(project => project.categories.includes(activeFilter))
    }
    setSelectedProjects(newProjects)
  }, [activeFilter])

  return (
    <div className="portfolio-container">
      <SectionContainer backgroundColor="white">
        <div className="portfolio__header">
          <Typography className="portfolio__header-text">
            Every <span className="color--primary">TISOHA CLIENT</span> is unique, our glory starts
            with clients and ends with their satisfaction. We are cooperating for your success!
          </Typography>
        </div>
        <div className="portfolio__projects">
          <ProjectFilter
            onFilterClick={handleFilterClick}
            filters={filters}
            activeFilter={activeFilter}
          />
        </div>
        <Box>
          <ProjectGrid projects={selectedProjects} />
        </Box>
      </SectionContainer>
      <Footer />
    </div>
  )
}
